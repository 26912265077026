<template>
  <CModal
    :title="product.id ? 'Thay đổi thông tin SP' : 'Thêm thông tin SP'"
    :show.sync="showing"
    :closeOnBackdrop="false"
    centered
  >
    <CRow>
      <CCol md="12">
        <CInput
          v-if="product.id && product.ProductId"
          type="text"
          :value="product.ProductName"
          disabled
        />
        <div v-else class="form-group">
          <v-select
            v-model="product.ProductId"
            :options="
              products && products.length
                ? products.map((_) => {
                    return { value: _.Id.toString(), label: _.Name };
                  })
                : []
            "
            :reduce="(p) => p.value"
            label="label"
            placeholder="Chọn Sản phẩm"
            :class="{
              'is-invalid border-danger rounded': !product.ProductId,
            }"
          />
          <div class="invalid-feedback">
            Vui lòng chọn SP
          </div>
        </div>
      </CCol>
      <CCol md="12">
        <div class="form-row">
          <CCol col="2">
            <CInput
              type="number"
              label="SL"
              placeholder="SL"
              :value="product.Qty"
              min="0"
              :add-input-classes="{
                'is-invalid': !product.Qty || product.Qty < 1,
              }"
              invalid-feedback="Nhập SL"
              @input="(e) => (product.Qty = e ? parseInt(e) : 0)"
              @focus="(e) => e.target.select()"
            />
          </CCol>
          <CCol col="5">
            <CInput
              type="text"
              label="Giá gốc"
              placeholder="Giá gốc"
              :value="$func.addCommas(product.MPrice)"
              disabled
            />
          </CCol>
          <CCol col="5">
            <CInput
              type="text"
              label="Giá sale"
              placeholder="Giá sale"
              :value="$func.addCommas(product.Price)"
              disabled
            />
          </CCol>
        </div>
      </CCol>
      <CCol md="12">
        <div class="form-row">
          <CCol col="4">
            <div class="form-row bg-gradient-light">
              <CCol tag="label" col="auto" class="col-form-label text-danger">
                Quà tặng?
              </CCol>
              <CCol col="auto">
                <CSwitch
                  class="mt-2"
                  color="info"
                  size="sm"
                  variant="opposite"
                  shape="pill"
                  :checked.sync="product.isGift"
                />
              </CCol>
            </div>
          </CCol>
          <CCol col="8">
            <CInput
              :plaintext="true"
              label="Thành tiền"
              :horizontal="{ label: 'col-4', input: 'col-8' }"
              :value="`${$func.addCommas(product.Qty * product.Price)} VND`"
            />
          </CCol>
        </div>
      </CCol>
    </CRow>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="showing = false">
        Hủy
      </button>
      <button type="button" class="btn btn-primary" @click="okHandle">
        Đồng ý
      </button>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["updating", "editingDetail"],
  data() {
    return {
      products: null,
      showing: false,
      product: {
        ProductId: 0,
        isGift: false,
      },
    };
  },
  watch: {
    editingDetail: {
      deep: true,
      handler(val) {
        this.product = Object.assign({}, val);
      },
    },

    "product.ProductId"(val) {
      if (val) {
        let product = this.products.find((_) => _.Id == val);
        this.product.ProductName = product ? product.Name : "";
        this.product.Price =
          product && !this.product.isGift ? product.Price : 0;
        this.product.MPrice = product ? product.MPrice : 0;
        this.product.OPrice = product ? product.Price : 0;
      } else {
        this.product.ProductName = "";
        this.product.Price = 0;
        this.product.MPrice = 0;
        this.product.OPrice = 0;
      }
    },
    "product.isGift"(val) {
      if (val) {
        this.product.Price = 0;
      } else {
        this.product.Price = this.product.OPrice;
      }
    },

    updating(val) {
      this.showing = val;
    },
    showing(val) {
      this.$emit("update:updating", val);
    },
  },
  async mounted() {
    if (this.products && this.products.length) {
      return;
    }
    this.products = await this.loadProducts();
  },
  methods: {
    async loadProducts() {
      let products = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Product", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name,Price,MPrice,ProductType",
          $orderby: "Name",
        },
      });
      if (resp && resp.status == 200) {
        products = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name:
              _.Name + " - " + this.$const.PRODUCT_TYPES_TEXT[_.ProductType],
            Price: _.Price,
            MPrice: _.MPrice,
          };
        });
      }
      return products;
    },

    okHandle() {
      if (
        this.product &&
        this.product.ProductId &&
        this.product.Qty &&
        this.product.Price >= 0 &&
        this.product.MPrice > 0
      ) {
        let product = Object.assign({}, this.product);
        product.ProductId = parseInt(product.ProductId);
        this.$emit("choose", product);
        this.showing = false;
      }
    },
  },
};
</script>
