<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <span class="font-weight-bold">
              <CIcon name="cil-NoteAdd" />
              Tạo đơn hàng
            </span>
            <CButton
              color="info"
              size="sm"
              class="float-right"
              @click="$router.push({ name: 'OrderList' })"
            >
              <CIcon name="cil-list" />
              Danh sách
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol md="3">
                <CInput
                  v-if="visitId && order.CustomerId"
                  :value="order.CustomerName"
                  label="Khách hàng"
                  placeholder="Khách hàng"
                  disabled
                />
                <div v-else class="form-group">
                  <label>Khách hàng</label>
                  <v-select
                    v-model="order.CustomerId"
                    :options="customers"
                    :filterable="false"
                    :reduce="(c) => c.Id"
                    @search="onSearchCustomers"
                    @option:selected="
                      (e) => {
                        order.BillAddress = {
                          Title: $const.TITLES[e.Title],
                          FullName: e.LastName
                            ? `${e.LastName} ${e.Name}`
                            : e.Name,
                          Mobile: e.Mobile,
                          Address: e.Address,
                          Province: e.Province,
                          District: e.District,
                          Ward: e.Ward,
                        };
                        order.DeliveryAddress = null;
                        isSameAddress = false;
                      }
                    "
                    label="Name"
                    placeholder="Khách hàng"
                    :class="{
                      'is-invalid border-danger rounded': !!inValidObject[
                        'order.CustomerId'
                      ],
                    }"
                  >
                    <template slot="no-options">
                      Nhập tên hoặc sđt để tìm kiếm KH
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="w-100">
                        <h5 class="mb-1">{{ option.Name }}</h5>
                        <p class="mb-1">
                          <span v-if="option.Mobile">
                            {{ option.Mobile }} -
                          </span>
                          {{
                            [
                              option.Address,
                              option.Ward,
                              option.District,
                              option.Province,
                            ]
                              .filter((_) => _)
                              .join(", ")
                          }}
                        </p>
                      </div>
                    </template>
                  </v-select>
                  <div class="invalid-feedback">
                    {{ inValidObject["order.CustomerId"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="4">
                <CInput
                  label="Địa chỉ hóa đơn"
                  placeholder="Địa chỉ hóa đơn"
                  :value="billAddress"
                  disabled
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['billAddress'],
                  }"
                  :invalid-feedback="inValidObject['billAddress']"
                >
                  <template #append>
                    <CButton
                      color="info"
                      v-if="order.CustomerId"
                      size="sm"
                      @click="selectingAddress = 'BillAddress'"
                    >
                      <CIcon name="cil-location-pin" />
                    </CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol md="5">
                <CInput
                  label="Địa chỉ giao hàng"
                  placeholder="Địa chỉ giao hàng"
                  :value="deliveryAddress"
                  disabled
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['deliveryAddress'],
                  }"
                  :invalid-feedback="inValidObject['deliveryAddress']"
                  class="position-relative"
                >
                  <template v-slot:label>
                    <label>
                      Địa chỉ giao hàng
                      <CSwitch
                        color="info"
                        size="sm"
                        variant="opposite"
                        shape="pill"
                        class="position-absolute"
                        style="right: 0px; top: 0px;"
                        :checked.sync="isSameAddress"
                      />
                    </label>
                  </template>
                  <template #append>
                    <CButton
                      color="info"
                      v-if="order.CustomerId && !isSameAddress"
                      size="sm"
                      @click="selectingAddress = 'DeliveryAddress'"
                    >
                      <CIcon name="cil-location-pin" />
                    </CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group">
                  <label>
                    Ngày giao dự kiến
                  </label>
                  <datetime
                    type="date"
                    v-model="order.EstimatedDeliveryDate"
                    format="dd/MM/yyyy"
                    :input-class="{
                      'form-control': true,
                      'is-invalid': !!inValidObject[
                        'order.EstimatedDeliveryDate'
                      ],
                    }"
                    :class="{
                      'is-invalid': !!inValidObject[
                        'order.EstimatedDeliveryDate'
                      ],
                    }"
                    value-zone="local"
                    :min-datetime="
                      !isAdmin
                        ? $moment()
                            .add(-1, 'day')
                            .startOf('day')
                            .toISOString()
                        : null
                    "
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["order.EstimatedDeliveryDate"] }}
                  </div>
                </div>
              </CCol>
              <CCol md="3">
                <CInput
                  v-if="visitId && order.VisitId"
                  :value="order.VisitName"
                  label="Thông tin ghé thăm"
                  placeholder="Thông tin ghé thăm"
                  disabled
                />
                <CSelect
                  v-else
                  :value.sync="order.VisitId"
                  label="Thông tin ghé thăm"
                  placeholder="Thông tin ghé thăm"
                  :options="
                    visits.map((_) => {
                      return { value: _.Id, label: _.Name };
                    })
                  "
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['order.VisitId'],
                  }"
                  :invalid-feedback="inValidObject['order.VisitId']"
                />
              </CCol>

              <CCol v-if="isAdmin" md="2">
                <CSelect
                  label="Nhân viên kinh doanh"
                  placeholder="Chọn Nhân viên"
                  :value.sync="order.SellerId"
                  :options="[
                    ...sellers.map((_) => {
                      return {
                        label: _.Name,
                        value: _.Id,
                      };
                    }),
                  ]"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['order.SellerId'],
                  }"
                  :invalid-feedback="inValidObject['order.SellerId']"
                />
              </CCol>

              <CCol :md="isAdmin ? '4' : '6'">
                <CInput
                  label="Ghi chú"
                  placeholder="Ghi chú"
                  v-model="order.Note"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol md="12">
                <div class="table-responsive">
                  <table class="table" ref="lstOrderDetails">
                    <thead>
                      <tr class="bg-light text-dark">
                        <th>STT</th>
                        <th style="min-width:200px;max-width:250px;">
                          Tên SP / CTKM
                        </th>
                        <th
                          class="text-right"
                          style="min-width:150px;max-width:200px;"
                        >
                          Đơn giá (VND)
                        </th>
                        <th
                          class="text-right"
                          style="min-width:150px;max-width:200px;"
                        >
                          Giảm giá
                        </th>
                        <th
                          class="text-right"
                          style="min-width:150px;max-width:200px;"
                        >
                          Tổng tạm (VND)
                        </th>
                        <th style="min-width:50px;max-width:50px;"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="orderDetails && orderDetails.length">
                        <tr
                          v-for="(orderDetail, i) in orderDetails"
                          :key="`orderDetail-${i}`"
                        >
                          <td>{{ i + 1 }}.</td>
                          <td style="min-width:200px;max-width:250px;">
                            {{ orderDetail.ProductName }}
                            <CIcon name="cil-gift" v-if="!orderDetail.Price" />
                          </td>
                          <td
                            class="text-right"
                            style="min-width:150px;max-width:200px;"
                          >
                            <a
                              href="javascript:"
                              class="text-dark text-decoration-none"
                              @click="
                                (e) => {
                                  updating = true;
                                  editingDetail = Object.assign(
                                    {},
                                    orderDetail
                                  );
                                }
                              "
                            >
                              {{ orderDetail.Qty }} x
                              {{ $func.addCommas(orderDetail.MPrice) }}
                              <CIcon name="cil-pencil"></CIcon>
                            </a>
                          </td>
                          <td
                            class="text-right"
                            style="min-width:150px;max-width:200px;"
                          >
                            Giảm
                            {{
                              orderDetail.Price
                                ? (
                                    ((orderDetail.MPrice - orderDetail.Price) /
                                      orderDetail.MPrice) *
                                    100
                                  ).toFixed(0)
                                : 100
                            }}%
                          </td>
                          <td
                            class="text-right font-weight-bold"
                            style="min-width:150px;max-width:200px;"
                          >
                            <a
                              href="javascript:"
                              class="text-dark text-decoration-none"
                            >
                              {{
                                $func.addCommas(
                                  orderDetail.Qty * orderDetail.Price
                                )
                              }}
                            </a>
                          </td>
                          <td style="min-width:50px;max-width:50px;">
                            <a
                              href="javascript:"
                              class="text-decoration-none text-danger"
                              @click="
                                (e) => {
                                  orderDetails = orderDetails.filter(
                                    (_) => _.id != orderDetail.id
                                  );
                                }
                              "
                            >
                              <CIcon name="cil-trash" />
                            </a>
                          </td>
                        </tr>
                      </template>
                      <template v-if="orderPromos && orderPromos.length">
                        <tr
                          v-for="(orderPromo, i) in orderPromos"
                          :key="`orderPromo-${i}`"
                        >
                          <td></td>
                          <td style="min-width:200px;max-width:250px;">
                            {{ orderPromo.PromoName }}
                            {{ 0 > orderPromo.Value ? "(Thu phí)" : "" }}
                            <CIcon
                              v-if="orderPromo.Value && orderPromo.Value > 0"
                              name="cil-mobile-landscape"
                            />
                          </td>
                          <td style="min-width:150px;max-width:200px;"></td>
                          <td
                            class="text-right"
                            style="min-width:150px;max-width:200px;"
                          >
                            <template
                              v-if="orderPromo.Value && orderPromo.Value > 0"
                            >
                              Giảm {{ $func.addCommas(orderPromo.Value) }}
                              {{ orderPromo.IsPercent ? "%" : "" }}
                            </template>
                          </td>
                          <td
                            class="text-right"
                            style="min-width:150px;max-width:200px;"
                          >
                            {{
                              orderPromo.Value && orderPromo.Value > 0
                                ? "-"
                                : ""
                            }}
                            <span v-if="orderPromo.IsPercent">
                              {{
                                $func.addCommas(
                                  subTotal * (orderPromo.Value / 100)
                                )
                              }}
                            </span>
                            <span v-else-if="orderPromo.Value > 0">
                              {{ $func.addCommas(orderPromo.Value) }}
                            </span>
                            <span v-else>
                              {{ $func.addCommas(-orderPromo.Value) }}
                            </span>
                          </td>
                          <td style="min-width:50px;max-width:50px;">
                            <a
                              href="javascript:"
                              class="text-decoration-none text-danger"
                              @click="
                                (e) => {
                                  orderPromos = orderPromos.filter(
                                    (_) => _.id != orderPromo.id
                                  );
                                }
                              "
                            >
                              <CIcon name="cil-trash" />
                            </a>
                          </td>
                        </tr>
                      </template>
                      <tr>
                        <td></td>
                        <td class="text-right" colspan="100%">
                          <a
                            href="javascript:"
                            class="text-info text-decoration-none"
                            @click="
                              (e) => {
                                updating = true;
                                editingDetail = { id: 0, Qty: 1, Price: 0 };
                              }
                            "
                          >
                            <CIcon name="cil-plus"></CIcon>
                            Thêm SP
                          </a>
                          |
                          <a
                            href="javascript:"
                            class="text-info text-decoration-none"
                            @click="
                              (e) => {
                                discounting = true;
                              }
                            "
                          >
                            <CIcon name="cil-plus"></CIcon>
                            Thêm CTKM
                          </a>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="bg-light text-dark">
                        <th colspan="100%" class="text-right">
                          Tổng:
                          <span class="text-dark font-weight-normal">
                            {{ $func.addCommas(subTotal) }}
                            {{ discount >= 0 ? "-" : "+" }}
                            <span role="button">
                              {{
                                $func.addCommas(
                                  discount >= 0 ? discount : -discount
                                )
                              }}
                              {{ discount >= 0 ? "(giảm giá)" : "(phí)" }}
                            </span>
                          </span>
                          = {{ $func.addCommas(grandTotal) }} VND
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="3">
                <CSelect
                  label="Hình thức thanh toán"
                  placeholder="Hình thức thanh toán"
                  :value.sync="order.ChargeType"
                  :options="
                    Object.keys($const.CHARGE_TYPES_TEXT).map((_) => {
                      return { value: _, label: $const.CHARGE_TYPES_TEXT[_] };
                    })
                  "
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['order.ChargeType'],
                  }"
                  :invalid-feedback="inValidObject['order.ChargeType']"
                />
              </CCol>
              <CCol
                md="3"
                v-if="order.ChargeType == $const.CHARGE_TYPES.Installment"
              >
                <div role="group" class="form-group">
                  <label class="text-danger font-weight-bold">
                    Tiền trả trước?
                  </label>
                  <money
                    :disabled="!!(order.Deposit && order.Deposit > 0)"
                    placeholder="Tiền trả trước"
                    :value="order.Prepay"
                    class="form-control text-right"
                    :class="{
                      'is-invalid': !!inValidObject['order.Prepay'],
                    }"
                    @input="
                      (e) => {
                        order.Prepay = e
                          ? parseFloat(e) >= 0
                            ? parseFloat(e)
                            : 0
                          : 0;
                      }
                    "
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["order.Prepay"] }}
                  </div>
                  <small class="form-text text-muted w-100">
                    {{
                      order.Prepay && grandTotal && grandTotal >= order.Prepay
                        ? `Trả trước ${(
                            (order.Prepay / grandTotal) *
                            100
                          ).toFixed(
                            0
                          )}% tổng tiền. Chuyển trả góp: ${$func.addCommas(
                            grandTotal - order.Prepay
                          )}. `
                        : ""
                    }}
                  </small>
                </div>
              </CCol>
              <CCol
                md="3"
                v-if="order.ChargeType == $const.CHARGE_TYPES.Installment"
              >
                <CSelect
                  :disabled="!!(order.Deposit && order.Deposit > 0)"
                  label="Thời gian trả góp?"
                  placeholder="Thời gian trả góp"
                  :value.sync="order.Months"
                  :options="
                    Object.keys($const.INSTALLMENT_MONTHS).map((_) => {
                      return { value: _, label: $const.INSTALLMENT_MONTHS[_] };
                    })
                  "
                  addLabelClasses="text-danger font-weight-bold"
                  :add-input-classes="{
                    'is-invalid': !!inValidObject['order.Months'],
                  }"
                  :invalid-feedback="inValidObject['order.Months']"
                  :description="
                    order.Prepay &&
                    grandTotal &&
                    grandTotal >= order.Prepay &&
                    order.Months
                      ? `Trả góp mỗi tháng: ${$func.addCommas(
                          Math.round(
                            (grandTotal - order.Prepay) / parseInt(order.Months)
                          )
                        )}`
                      : ''
                  "
                />
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group">
                  <label>
                    Tiền cọc
                  </label>
                  <money
                    :disabled="
                      !!(
                        order.ChargeType == $const.CHARGE_TYPES.Installment &&
                        order.Prepay &&
                        order.Prepay > 0
                      )
                    "
                    placeholder="Tiền cọc"
                    :value="order.Deposit"
                    class="form-control text-right"
                    :class="{
                      'is-invalid': !!inValidObject['order.Deposit'],
                    }"
                    @input="
                      (e) => {
                        order.Deposit = e
                          ? parseFloat(e) >= 0
                            ? parseFloat(e)
                            : 0
                          : 0;
                      }
                    "
                  />
                  <div class="invalid-feedback">
                    {{ inValidObject["order.Deposit"] }}
                  </div>
                  <small class="form-text text-muted w-100">
                    {{
                      order.Deposit && grandTotal && grandTotal >= order.Deposit
                        ? `Hiện đang đặt cọc ${(
                            (order.Deposit / grandTotal) *
                            100
                          ).toFixed(0)}% tổng tiền`
                        : ""
                    }}
                  </small>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="1">
                <div role="group" class="form-group">
                  <label class="font-weight-bold text-danger">
                    Chia bill?
                  </label>
                  <div class="bg-gradient-light pl-2 pb-2 rounded">
                    <CSwitch
                      class="col-form-label"
                      color="info"
                      size="sm"
                      shape="pill"
                      label
                      :checked.sync="order.IsShared"
                    />
                  </div>
                </div>
              </CCol>
              <template v-if="order.IsShared">
                <CCol md="3">
                  <CSelect
                    label="Cách thức chia bill"
                    placeholder="Chọn cách thức"
                    :value.sync="order.SharedType"
                    :options="
                      Object.keys($const.SHARED_TYPES_TEXT).map((_) => {
                        return { value: _, label: $const.SHARED_TYPES_TEXT[_] };
                      })
                    "
                    :add-input-classes="{
                      'is-invalid': !!inValidObject['order.SharedType'],
                    }"
                    :invalid-feedback="inValidObject['order.SharedType']"
                  />
                </CCol>
                <CCol md="4">
                  <div class="form-group">
                    <label>Cửa hàng chia bill</label>
                    <v-select
                      v-model="order.SharedAgencyId"
                      :options="agencies"
                      :reduce="(p) => p.Id"
                      label="Name"
                      placeholder="Chọn cửa hàng"
                      :class="{
                        'is-invalid border-danger rounded': !!inValidObject[
                          'order.SharedAgencyId'
                        ],
                      }"
                    />
                    <div class="invalid-feedback">
                      {{ inValidObject["order.SharedAgencyId"] }}
                    </div>
                  </div>
                </CCol>
                <CCol md="4">
                  <CSelect
                    label="Nhân viên chia bill"
                    placeholder="Chọn Nhân viên"
                    :value.sync="order.SharedUserId"
                    :options="[
                      ...sharedSellers.map((_) => {
                        return {
                          label: _.Name,
                          value: _.Id,
                        };
                      }),
                    ]"
                    :add-input-classes="{
                      'is-invalid': !!inValidObject['order.SharedUserId'],
                    }"
                    :invalid-feedback="inValidObject['order.SharedUserId']"
                  />
                </CCol>
                <CCol
                  md="12"
                  v-if="
                    order.SharedType &&
                      order.SharedAgencyId &&
                      order.SharedUserId
                  "
                >
                  <div class="text-right font-italic">
                    <span class="font-weight-bold">Diễn giải:</span>
                    {{
                      getSharedDescription(
                        order.SharedType,
                        order.SharedAgencyId,
                        order.SharedUserId
                      )
                    }}
                  </div>
                </CCol>
              </template>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" :disabled="saving" @click="createOrder">
              <CIcon name="cil-save" />
              Tạo đơn hàng
            </CButton>
            <div class="d-inline-block font-italic" v-if="saving">
              <CSpinner color="info" size="sm" class="ml-2" />
              Đang xử lý...
            </div>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <choose-product
      :updating.sync="updating"
      :editingDetail="editingDetail"
      @choose="updateOrderDetail"
    />
    <choose-address
      :selectingAddress.sync="selectingAddress"
      :customerId="order.CustomerId"
      @choose="updateAddress"
    />
    <choose-promo
      :updating.sync="discounting"
      :subTotal="subTotal"
      @choose="updateOrderPromo"
    />
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

import ChooseProduct from "./ChooseProduct.vue";
import ChoosePromo from "./ChoosePromo.vue";
import ChooseAddress from "@/views/address/List.vue";
export default {
  components: { ChooseProduct, ChooseAddress, ChoosePromo, datetime: Datetime },
  data() {
    return {
      isSameAddress: false,
      order: {},
      orderDetails: [],
      updating: false,
      editingDetail: null,
      inValidObject: {},
      saving: false,
      customers: [],
      visits: [],
      selectingAddress: null,
      discounting: false,
      orderPromos: [],
      agencies: [],
      sharedSellers: [],
      sellers: [],
    };
  },
  watch: {
    isSameAddress(val) {
      if (val) {
        this.order.DeliveryAddress = Object.assign({}, this.order.BillAddress);
      }
    },
    async "order.CustomerId"(val) {
      let results = [];
      if (val && !this.visitId) {
        let resp = await this.$http.get(`odata/CustomerVisit`, {
          params: {
            $top: 100,
            $filter: `CustomerId eq ${val} and contains(Details, '"NextStep":"Bill"')`,
            $orderby: "CreatedAt desc",
          },
        });
        if (resp && resp.status == 200) {
          let data = resp.data.value;
          if (data) {
            if (this.isAdmin) {
              results = data.map((_) => {
                return {
                  Id: _.Id,
                  Name: `Ghé ${this.$moment
                    .utc(_.CreatedAt)
                    .local()
                    .format("dddd DD/MM/YYYY HH:mm")}`,
                  AgencyId: _.AgencyId,
                };
              });
            } else {
              let minDate = this.$moment()
                .add(-1, "day")
                .startOf("day")
                .utc();
              results = data
                .filter((_) => this.$moment.utc(_.CreatedAt) >= minDate)
                .map((_) => {
                  return {
                    Id: _.Id,
                    Name: `Ghé ${this.$moment
                      .utc(_.CreatedAt)
                      .local()
                      .format("dddd DD/MM/YYYY HH:mm")}`,
                    AgencyId: _.AgencyId,
                  };
                });
            }
          }
        }
      }
      this.visits = results;
    },
    "order.ChargeType"() {
      this.order.Deposit = 0;
      this.order.Prepay = 0;
    },
    async "order.IsShared"(val) {
      this.order.SharedAgencyId = null;
      this.order.SharedUserId = null;
      this.order.SharedType = this.defaultSharedType;

      if (val && (!this.agencies || !this.agencies.length)) {
        this.agencies = await this.loadAgencies();
      }
    },
    async "order.SharedAgencyId"(val) {
      this.order.SharedUserId = null;

      let results = [];
      if (val) {
        results = await this.loadSellers(val);
      }

      this.sharedSellers = results;
    },
    async "order.VisitId"(val) {
      this.order.SellerId = null;
      let results = [];
      if (val && this.isAdmin) {
        let visit = this.visits.find((_) => _.Id == val);
        if (visit) {
          results = await this.loadSellers(visit.AgencyId);
        }
      }

      this.sellers = results;
    },
  },
  computed: {
    emptyOrder() {
      return {
        CustomerId: null,
        CustomerName: null,
        VisitId: null,
        VisitName: null,
        Note: null,
        BillAddress: null,
        DeliveryAddress: null,
        EstimatedDeliveryDate: new Date().toISOString(),
        ChargeType: this.$const.CHARGE_TYPES.Cash,
        Prepay: 0,
        Months: this.defaultMonth,
        Deposit: 0,
        IsShared: false,
        SharedAgencyId: null,
        SharedUserId: null,
        SharedType: this.defaultSharedType,
        SellerId: null,
      };
    },
    visitId() {
      let visitId = this.$route.params.visitId;
      return !isNaN(visitId) ? visitId : 0;
    },
    billAddress() {
      let address = this.order.BillAddress;
      if (address) {
        return [
          address.Title + " " + address.FullName,
          address.Mobile ? "ĐT: " + address.Mobile : "",
          "ĐC: " +
            [address.Address, address.Ward, address.District, address.Province]
              .filter((_) => _)
              .join(", "),
        ]
          .filter((_) => _)
          .join(", ");
      }
      return "";
    },
    deliveryAddress() {
      let address = this.order.DeliveryAddress;
      if (address) {
        return [
          address.Title + " " + address.FullName,
          address.Mobile ? "ĐT: " + address.Mobile : "",
          "ĐC: " +
            [address.Address, address.Ward, address.District, address.Province]
              .filter((_) => _)
              .join(", "),
        ]
          .filter((_) => _)
          .join(", ");
      }
      return "";
    },
    subTotal() {
      let details = this.orderDetails;
      return details && details.length
        ? details.reduce((acc, cur) => acc + cur.Qty * cur.Price, 0)
        : 0;
    },
    discount() {
      let subTotal = this.subTotal;
      let orderPromos = this.orderPromos;
      if (subTotal && orderPromos && orderPromos.length) {
        let discount = 0;
        orderPromos.forEach((_) => {
          let rowDiscount = _.IsPercent ? (subTotal * _.Value) / 100 : _.Value;
          discount += rowDiscount;
        });
        return discount;
      }

      return 0;
    },
    grandTotal() {
      let discount = this.discount;
      let subTotal = this.subTotal;

      if (subTotal >= discount) {
        return subTotal - discount;
      }
      return 0;
    },
    defaultMonth() {
      return Object.keys(this.$const.INSTALLMENT_MONTHS)[0];
    },
    defaultSharedType() {
      return null;
    },

    isAdmin() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser && authUser.role == this.$const.ROLES.SuperAdmin
        : false;
    },
  },
  async mounted() {
    await this.loadVisit(this.visitId);

    if (
      this.$refs.lstOrderDetails &&
      this.$refs.lstOrderDetails.parentElement
    ) {
      this.$refs.lstOrderDetails.parentElement.scrollLeft = this.$refs.lstOrderDetails.clientWidth;
    }
  },
  methods: {
    async loadVisit(visitId) {
      this.order = this.emptyOrder;
      this.orderDetails = [];

      if (visitId) {
        let resp = await this.$http.get(`odata/CustomerVisit`, {
          params: {
            $top: 1,
            $filter: `Id eq ${visitId}`,
            $expand:
              `Customer($select=Title,Name,Mobile,Address;` +
              `$expand=ProvinceCodeNavigation($select=Name),DistrictCodeNavigation($select=Name),WardCodeNavigation($select=Name)),` +
              `Product($select=Name,Price,MPrice)`,
          },
        });
        if (resp && resp.status == 200) {
          let data = resp.data.value[0];
          if (data) {
            let province = data.Customer.ProvinceCodeNavigation;
            let district = data.Customer.DistrictCodeNavigation;
            let ward = data.Customer.WardCodeNavigation;
            this.order = {
              CustomerId: data.CustomerId,
              CustomerName: data.Customer.Name,
              VisitId: data.Id,
              VisitName: `Ghé ${this.$moment
                .utc(data.CreatedAt)
                .local()
                .format("dddd DD/MM/YYYY HH:mm")}`,
              Note: data.Note,
              BillAddress: {
                Title: this.$const.TITLES[data.Customer.Title],
                FullName: data.Customer.LastName
                  ? `${data.Customer.LastName} ${data.Customer.Name}`
                  : data.Customer.Name,
                Mobile: data.Customer.Mobile,
                Address: data.Customer.Address,
                Province: province ? province.Name : "",
                District: district ? district.Name : "",
                Ward: ward ? ward.Name : "",
              },
              DeliveryAddress: null,
              EstimatedDeliveryDate: data.IsPast
                ? data.CreatedAt
                : new Date().toISOString(),
              ChargeType: this.$const.CHARGE_TYPES.Cash,
              Prepay: 0,
              Months: this.defaultMonth,
              Deposit: 0,
              IsShared: false,
              SharedAgencyId: null,
              SharedUserId: null,
              SharedType: this.defaultSharedType,
              SellerId: null,
            };
            if (data.ProductId) {
              this.orderDetails = [
                {
                  id: this.$func.create_UUID(),
                  ProductId: data.ProductId,
                  ProductName: data.Product.Name,
                  Price: data.Product.Price,
                  MPrice: data.Product.MPrice,
                  Qty: 1,
                },
              ];
            }

            if (this.isAdmin && data.AgencyId) {
              this.sellers = await this.loadSellers(data.AgencyId);
            }
          }
        }
      }
    },

    updateOrderDetail(product) {
      if (this.orderDetails.some((_) => _.ProductId == product.ProductId)) {
        // update order detail
        this.orderDetails
          .filter((_) => _.ProductId == product.ProductId)
          .forEach((_) => {
            _.Qty = product.Qty;
            _.Price = product.Price;
            _.MPrice = product.MPrice;
          });
      } else {
        // insert into order details
        let id = this.$func.create_UUID();
        product.id = id;
        this.orderDetails.push(product);
      }
    },

    updateOrderPromo(promotion) {
      let id = this.$func.create_UUID();
      promotion.id = id;
      this.orderPromos.push(promotion);
    },

    updateAddress({ address, selectingAddress }) {
      // selectingAddress: BillAddress | DeliveryAddress
      this.order[selectingAddress] = address;
    },

    async createOrder() {
      let order = this.order;
      let orderDetails = this.orderDetails;
      let orderPromos = this.orderPromos;

      this.inValidObject = this.checkValid(
        order,
        this.billAddress,
        this.deliveryAddress,
        this.grandTotal,
        this.isAdmin
      );

      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }
      if (!orderDetails || !orderDetails.length) {
        alert("Chưa có thông tin sản phẩm");
        return;
      }
      this.saving = true;
      try {
        let resp = await this.$http.post(`Order/Create`, {
          Order: {
            CustomerId: order.CustomerId,
            Discount: this.discount,
            ChargeType: parseInt(order.ChargeType),
            BillAddress: JSON.stringify(order.BillAddress),
            DeliveryAddress: JSON.stringify(order.DeliveryAddress),
            EstimatedDeliveryDate: this.$moment(
              order.EstimatedDeliveryDate
            ).toISOString(),
            VisitId: order.VisitId,
            Note: order.Note,
            Deposit: order.Deposit,
            Prepay:
              order.ChargeType == this.$const.CHARGE_TYPES.Installment
                ? order.Prepay
                : null,
            Months:
              order.ChargeType == this.$const.CHARGE_TYPES.Installment
                ? order.Months
                : null,
            SharedAgencyId: order.IsShared ? order.SharedAgencyId : null,
            SharedUserId: order.IsShared ? order.SharedUserId : null,
            SharedType: order.IsShared ? order.SharedType : null,
            SellerId: order.SellerId ? order.SellerId : null,
          },
          Details: orderDetails.map((_) => {
            return {
              ProductId: _.ProductId,
              ProductName: _.ProductName,
              Qty: _.Qty,
              Price: _.Price,
              MPrice: _.MPrice,
            };
          }),
          Promos: orderPromos.map((_) => {
            return {
              PromoId: _.PromoId,
              PromoName: _.PromoName,
              IsPercent: _.IsPercent,
              Value: _.Value,
            };
          }),
        });
        if (resp && resp.status == 200) {
          if (resp.data.status == this.$const.RESPONSE_TYPES.Success) {
            let data = resp.data.data;
            if (data) {
              this.$router.push({
                name: "OrderList",
                params: { orderId: data.id },
              });
            } else {
              alert(this.$const.MESSAGE.CreateOrderSuccess);

              this.$router.push({ name: "OrderList" });
            }
          } else if (resp.data.message) {
            alert(this.$const.MESSAGE[resp.data.message]);
          }
        } else {
          alert(this.$const.MESSAGE.InvalidOrderInfo);
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    async onSearchCustomers(searchName, loading) {
      if (searchName.length) {
        loading(true);

        let results = [];
        try {
          let filters = [];
          filters.push(`Status eq ${this.$const.STATUS.Active}`);
          searchName &&
            isNaN(searchName) &&
            filters.push(`contains(Name, '${searchName}')`);
          searchName &&
            !isNaN(searchName) &&
            filters.push(`contains(Mobile, '${searchName}')`);
          let resp = await this.$http.get(`odata/Customer`, {
            params: {
              $top: 10,
              $filter: filters.join(" and "),
              $select:
                "Id,Title,Name,LastName,Mobile,Email,FacebookLink,Address",
              isAll: true,
              $expand:
                "ProvinceCodeNavigation($select=Name),DistrictCodeNavigation($select=Name),WardCodeNavigation($select=Name)",
            },
          });
          if (resp && resp.status == 200) {
            results = resp.data.value.map((_) => {
              let province = _.ProvinceCodeNavigation;
              let district = _.DistrictCodeNavigation;
              let ward = _.WardCodeNavigation;

              return {
                Id: _.Id,
                Title: _.Title,
                Name: _.Name,
                LastName: _.LastName,
                Mobile: _.Mobile,
                Email: _.Email,
                Address: _.Address,
                Province: province ? province.Name : "",
                District: district ? district.Name : "",
                Ward: ward ? ward.Name : "",
              };
            });
          }
        } catch (error) {
          alert(error);
        }
        loading(false);
        this.customers = results;
      }
    },

    checkValid(order, billAddress, deliveryAddress, grandTotal, isAdmin) {
      let inValidObject = {};

      if (!order.CustomerId) {
        inValidObject["order.CustomerId"] = "Vui lòng nhập thông tin KH";
      }
      if (!billAddress) {
        inValidObject["billAddress"] = "Vui lòng nhập Địa chỉ hóa đơn";
      }
      if (!deliveryAddress) {
        inValidObject["deliveryAddress"] = "Vui lòng nhập Địa chỉ giao hàng";
      }
      if (!order.EstimatedDeliveryDate) {
        inValidObject["order.EstimatedDeliveryDate"] =
          "Vui lòng nhập Ngày giao dự kiến";
      }
      if (!order.ChargeType) {
        inValidObject["order.ChargeType"] =
          "Vui lòng chọn Hình thức thanh toán";
      }
      if (!order.VisitId) {
        inValidObject["order.VisitId"] = "Vui lòng chọn Thông tin ghé thăm";
      }

      if (order.ChargeType == this.$const.CHARGE_TYPES.Installment) {
        if (order.Prepay) {
          if (order.Prepay < 0) {
            inValidObject["order.Prepay"] = "Tiền Trả trước phải lớn hơn 0";
          } else if (order.Prepay > grandTotal) {
            inValidObject["order.Prepay"] =
              "Tiền Trả trước phải nhỏ hơn tổng tiền";
          }
        } else if (order.Deposit) {
          if (order.Deposit < 0) {
            inValidObject["order.Deposit"] = "Tiền cọc phải lớn hơn 0";
          } else if (order.Deposit > grandTotal) {
            inValidObject["order.Deposit"] = "Tiền cọc phải nhỏ hơn tổng tiền";
          }
        } else {
          inValidObject["order.Deposit"] = inValidObject[
            "order.Prepay"
          ] = inValidObject["order.Months"] =
            "Nhập thông tin Trả góp hoặc Tiền cọc";
        }
      } else if (order.Deposit) {
        if (order.Deposit < 0) {
          inValidObject["order.Deposit"] = "Tiền cọc phải lớn hơn 0";
        } else if (order.Deposit > grandTotal) {
          inValidObject["order.Deposit"] = "Tiền cọc phải nhỏ hơn tổng tiền";
        }
      } else if (grandTotal > 0) {
        inValidObject["order.Deposit"] = "Vui lòng nhập Tiền cọc";
      }

      if (order.IsShared) {
        if (!order.SharedAgencyId) {
          inValidObject["order.SharedAgencyId"] =
            "Vui lòng chọn Cửa hàng chia bill";
        }
        if (!order.SharedUserId) {
          inValidObject["order.SharedUserId"] =
            "Vui lòng chọn Nhân viên chia bill";
        }
        if (!order.SharedType) {
          inValidObject["order.SharedType"] =
            "Vui lòng chọn Cách thức chia bill";
        }
      }

      if (isAdmin) {
        if (!order.SellerId) {
          inValidObject["order.SellerId"] =
            "Vui lòng chọn Nhân viên kinh doanh";
        }
      }

      return inValidObject;
    },

    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 200,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },

    async loadSellers(agencyId) {
      let sellers = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/User", {
        params: {
          $top: 1000,
          $filter: [
            `Role in ('${this.$const.ROLES.Owner}', '${this.$const.ROLES.Agent}')`,
            `Status eq ${active}`,
            `(${[
              `startswith(AgencyIds, '${agencyId},')`,
              `endswith(AgencyIds, ',${agencyId}')`,
              `contains(AgencyIds, ',${agencyId},')`,
              `AgencyIds eq '${agencyId}'`,
            ].join(" or ")})`,
          ].join(" and "),
          $select: "Id,Name",
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        sellers = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return sellers;
    },

    getSharedDescription(sharedType, sharedAgencyId, sharedUserId) {
      let typeDescr = this.$const.SHARED_TYPES_TEXT[sharedType];
      let agencyName = this.agencies.find((_) => _.Id == sharedAgencyId).Name;
      let sellerName = this.sharedSellers.find((_) => _.Id == sharedUserId)
        .Name;
      return `${typeDescr}. Cửa hàng: ${agencyName}. Nhân viên: ${sellerName}`;
    },
  },
};
</script>
