var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":"Chọn chương trình KM","show":_vm.showing,"closeOnBackdrop":false,"size":"sm","centered":""},on:{"update:show":function($event){_vm.showing=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.showing = false}}},[_vm._v(" Hủy ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.okHandle}},[_vm._v(" Đồng ý ")])]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"placeholder":"Chọn chương trình KM","value":_vm.promotion.PromoId,"add-input-classes":{
          'is-invalid': !_vm.promotion.PromoId,
        },"invalid-feedback":"Vui lòng chọn KM","options":_vm.promotions && _vm.promotions.length
            ? _vm.promotions.map(function (_) {
                return { value: _.Id, label: _.Name };
              })
            : []},on:{"update:value":function($event){return _vm.$set(_vm.promotion, "PromoId", $event)}}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"col":"5"}},[_c('CInput',{attrs:{"type":"text","label":"Loại KM","placeholder":"Loại KM","value":_vm.promotion.IsPercent ? 'Giảm %' : 'Giảm tiền',"disabled":""}})],1),_c('CCol',{attrs:{"col":"7"}},[(_vm.promotion.PromoId == 99 && !_vm.promotion.IsPercent)?_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Số tiền ")]),_c('money',{staticClass:"form-control text-right",class:{
                'is-invalid': !_vm.promotion.Value || _vm.promotion.Value >= 1000000,
              },attrs:{"placeholder":"Số tiền","value":_vm.promotion.Value},on:{"input":function (e) {
                  _vm.promotion.Value = e ? parseInt(e) : 0;
                  _vm.$forceUpdate();
                }}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Số tiền bắt buộc và không vượt quá 1tr ")])],1):_c('CInput',{attrs:{"type":"text","label":_vm.promotion.IsPercent ? 'Số % giảm' : 'Số tiền giảm',"placeholder":_vm.promotion.IsPercent ? 'Số % giảm' : 'Số tiền giảm',"value":_vm.promotion && _vm.promotion.Value
                ? ((_vm.$func.addCommas(_vm.promotion.Value)) + " " + (_vm.promotion.IsPercent ? '%' : 'VND'))
                : '',"disabled":""}})],1)],1)]),_c('CCol',[_c('CInput',{attrs:{"plaintext":true,"label":"Thành tiền","horizontal":{ label: 'col-4', input: 'col-8' },"value":((_vm.$func.addCommas(_vm.total)) + " VND")}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }