<template>
  <CModal
    :title="
      `Chọn địa chỉ ${
        selectingAddress == 'BillAddress' ? 'hóa đơn' : 'giao hàng'
      }`
    "
    :show.sync="showing"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CCard v-if="customerId && showing">
          <CCardHeader>
            <span class="font-weight-bold">
              <CIcon name="cilAddressBook" />
              Danh sách địa chỉ
            </span>
            <CButton
              color="success"
              size="sm"
              class="float-right"
              @click.prevent="
                (e) => {
                  editing = true;
                  customerAddressId = 0;
                }
              "
            >
              <CIcon name="cil-plus" />
              Thêm dịa chỉ
            </CButton>
          </CCardHeader>
          <CCardBody>
            <odata-table
              ref="lstCustAddress"
              url="odata/CustomerAddress"
              :filter="filter"
              select="Id,Title,Name,Mobile,Address,CreatedAt,ProvinceCode,DistrictCode,WardCode"
              :expand="
                `ProvinceCodeNavigation($select=Name), ` +
                  `DistrictCodeNavigation($select=Name), ` +
                  `WardCodeNavigation($select=Name)`
              "
              sortBy="CreatedAt desc"
              :pageSize="pageSize"
              :colSetting="{
                Action: {
                  display: '#',
                  sortable: false,
                  style: 'min-width: 70px;',
                },
                Name: {
                  field: 'Name',
                  display: 'Tên KH',
                  sortable: true,
                  style: 'min-width: 150px',
                },
                Mobile: {
                  field: 'Mobile',
                  display: 'Điện thoại',
                  sortable: true,
                  style: 'min-width: 120px',
                },
                Address: {
                  field: 'Address',
                  display: 'Địa chỉ',
                  sortable: true,
                  style: 'min-width: 200px',
                },
                CreatedAt: {
                  field: 'CreatedAt',
                  display: 'Ngày tạo',
                  sortable: true,
                  style: 'min-width: 120px',
                },
              }"
            >
              <template v-slot:tbody="{ rows }">
                <tr v-for="(row, i) in rows" :key="`tr-${i}`">
                  <td>
                    <CInputRadio
                      :custom="true"
                      :name="customerId"
                      :checked="selecting && selecting.Id == row.Id"
                      @change="selecting = row"
                      class="d-inline-block"
                    />
                    |
                    <a
                      href="javascript:"
                      class="text-danger"
                      @click="deleteCustomerAddress(row)"
                    >
                      <CIcon name="cil-trash" />
                    </a>
                  </td>
                  <td>
                    <a
                      href="javascript:"
                      class="text-primary"
                      :title="`Cập nhật KH: ${row.Name}`"
                      @click="
                        (e) => {
                          customerAddressId = row.Id;
                          editing = true;
                        }
                      "
                    >
                      <CIcon name="cil-pencil" />
                      {{ $const.TITLES[row.Title] }} {{ row.Name }}
                    </a>
                  </td>
                  <td>{{ row.Mobile }}</td>
                  <td class="text-truncate" style="max-width:200px;">
                    <span :title="getAddress(row)">
                      {{ getAddress(row) }}
                    </span>
                  </td>
                  <td>
                    {{
                      $moment
                        .utc(row.CreatedAt)
                        .local()
                        .format("DD/MM/YY HH:mm")
                    }}
                  </td>
                </tr>
              </template>
            </odata-table>
          </CCardBody>
        </CCard>
        <detail
          :editing.sync="editing"
          :customerAddressId.sync="customerAddressId"
          :customerId="customerId"
          @reload="$refs.lstCustAddress.loadData()"
        />
      </div>
    </template>

    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="showing = false">
        Hủy
      </button>
      <button type="button" class="btn btn-primary" @click="okHandle">
        Đồng ý
      </button>
    </template>
  </CModal>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  props: ["selectingAddress", "customerId"],
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      showing: false,
      editing: false,
      customerAddressId: 0,
      pageSize: 10,
      selecting: null,
    };
  },
  computed: {
    filter() {
      let filters = [];

      filters.push(`CustomerId eq ${this.customerId}`);

      return filters.join(" and ");
    },
  },
  watch: {
    selectingAddress(val) {
      this.showing = !!val;
    },
    showing(val) {
      if (val) {
        this.selecting = null;
      } else {
        this.$emit("update:selectingAddress", null);
      }
    },
  },
  methods: {
    okHandle() {
      let selecting = this.selecting;
      if (!selecting) {
        alert("Vui lòng chọn một địa chỉ!");
        return;
      }

      let address = {
        Title: this.$const.TITLES[selecting.Title],
        FullName: selecting.Name,
        Mobile: selecting.Mobile,
        Address: selecting.Address,
        Province: selecting.ProvinceCodeNavigation
          ? selecting.ProvinceCodeNavigation.Name
          : "",
        District: selecting.DistrictCodeNavigation
          ? selecting.DistrictCodeNavigation.Name
          : "",
        Ward: selecting.WardCodeNavigation
          ? selecting.WardCodeNavigation.Name
          : "",
      };
      let selectingAddress = this.selectingAddress;
      this.$emit("choose", { address, selectingAddress });
      this.showing = false;
    },

    getAddress(row) {
      return [
        row.Address,
        row.WardCodeNavigation ? row.WardCodeNavigation.Name : "",
        row.DistrictCodeNavigation ? row.DistrictCodeNavigation.Name : "",
        row.ProvinceCodeNavigation ? row.ProvinceCodeNavigation.Name : "",
      ]
        .filter((_) => _)
        .join(", ");
    },

    async deleteCustomerAddress(customerAddress) {
      if (confirm("Bạn chắc chắn muốn xóa địa chỉ này không?")) {
        try {
          let resp = await this.$http.delete(
            `odata/CustomerAddress/${customerAddress.Id}`
          );
          if (resp && resp.status == 204) {
            this.$refs.lstCustAddress.loadData();
          } else {
            alert("Lỗi cập nhật thông tin");
          }
        } catch (error) {
          alert(error);
        }
      }
    },
  },
};
</script>
