var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.product.id ? 'Thay đổi thông tin SP' : 'Thêm thông tin SP',"show":_vm.showing,"closeOnBackdrop":false,"centered":""},on:{"update:show":function($event){_vm.showing=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.showing = false}}},[_vm._v(" Hủy ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.okHandle}},[_vm._v(" Đồng ý ")])]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[(_vm.product.id && _vm.product.ProductId)?_c('CInput',{attrs:{"type":"text","value":_vm.product.ProductName,"disabled":""}}):_c('div',{staticClass:"form-group"},[_c('v-select',{class:{
            'is-invalid border-danger rounded': !_vm.product.ProductId,
          },attrs:{"options":_vm.products && _vm.products.length
              ? _vm.products.map(function (_) {
                  return { value: _.Id.toString(), label: _.Name };
                })
              : [],"reduce":function (p) { return p.value; },"label":"label","placeholder":"Chọn Sản phẩm"},model:{value:(_vm.product.ProductId),callback:function ($$v) {_vm.$set(_vm.product, "ProductId", $$v)},expression:"product.ProductId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Vui lòng chọn SP ")])],1)],1),_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"col":"2"}},[_c('CInput',{attrs:{"type":"number","label":"SL","placeholder":"SL","value":_vm.product.Qty,"min":"0","add-input-classes":{
              'is-invalid': !_vm.product.Qty || _vm.product.Qty < 1,
            },"invalid-feedback":"Nhập SL"},on:{"input":function (e) { return (_vm.product.Qty = e ? parseInt(e) : 0); },"focus":function (e) { return e.target.select(); }}})],1),_c('CCol',{attrs:{"col":"5"}},[_c('CInput',{attrs:{"type":"text","label":"Giá gốc","placeholder":"Giá gốc","value":_vm.$func.addCommas(_vm.product.MPrice),"disabled":""}})],1),_c('CCol',{attrs:{"col":"5"}},[_c('CInput',{attrs:{"type":"text","label":"Giá sale","placeholder":"Giá sale","value":_vm.$func.addCommas(_vm.product.Price),"disabled":""}})],1)],1)]),_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"col":"4"}},[_c('div',{staticClass:"form-row bg-gradient-light"},[_c('CCol',{staticClass:"col-form-label text-danger",attrs:{"tag":"label","col":"auto"}},[_vm._v(" Quà tặng? ")]),_c('CCol',{attrs:{"col":"auto"}},[_c('CSwitch',{staticClass:"mt-2",attrs:{"color":"info","size":"sm","variant":"opposite","shape":"pill","checked":_vm.product.isGift},on:{"update:checked":function($event){return _vm.$set(_vm.product, "isGift", $event)}}})],1)],1)]),_c('CCol',{attrs:{"col":"8"}},[_c('CInput',{attrs:{"plaintext":true,"label":"Thành tiền","horizontal":{ label: 'col-4', input: 'col-8' },"value":((_vm.$func.addCommas(_vm.product.Qty * _vm.product.Price)) + " VND")}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }