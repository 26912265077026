var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":("Chọn địa chỉ " + (_vm.selectingAddress == 'BillAddress' ? 'hóa đơn' : 'giao hàng')),"show":_vm.showing,"closeOnBackdrop":false,"centered":"","size":"lg"},on:{"update:show":function($event){_vm.showing=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[(_vm.customerId && _vm.showing)?_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilAddressBook"}}),_vm._v(" Danh sách địa chỉ ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
                _vm.editing = true;
                _vm.customerAddressId = 0;
              })($event)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm dịa chỉ ")],1)],1),_c('CCardBody',[_c('odata-table',{ref:"lstCustAddress",attrs:{"url":"odata/CustomerAddress","filter":_vm.filter,"select":"Id,Title,Name,Mobile,Address,CreatedAt,ProvinceCode,DistrictCode,WardCode","expand":"ProvinceCodeNavigation($select=Name), " +
                "DistrictCodeNavigation($select=Name), " +
                "WardCodeNavigation($select=Name)","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
              Action: {
                display: '#',
                sortable: false,
                style: 'min-width: 70px;',
              },
              Name: {
                field: 'Name',
                display: 'Tên KH',
                sortable: true,
                style: 'min-width: 150px',
              },
              Mobile: {
                field: 'Mobile',
                display: 'Điện thoại',
                sortable: true,
                style: 'min-width: 120px',
              },
              Address: {
                field: 'Address',
                display: 'Địa chỉ',
                sortable: true,
                style: 'min-width: 200px',
              },
              CreatedAt: {
                field: 'CreatedAt',
                display: 'Ngày tạo',
                sortable: true,
                style: 'min-width: 120px',
              },
            }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
            var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_c('CInputRadio',{staticClass:"d-inline-block",attrs:{"custom":true,"name":_vm.customerId,"checked":_vm.selecting && _vm.selecting.Id == row.Id},on:{"change":function($event){_vm.selecting = row}}}),_vm._v(" | "),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.deleteCustomerAddress(row)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật KH: " + (row.Name))},on:{"click":function (e) {
                        _vm.customerAddressId = row.Id;
                        _vm.editing = true;
                      }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(_vm.$const.TITLES[row.Title])+" "+_vm._s(row.Name)+" ")],1)]),_c('td',[_vm._v(_vm._s(row.Mobile))]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_c('span',{attrs:{"title":_vm.getAddress(row)}},[_vm._v(" "+_vm._s(_vm.getAddress(row))+" ")])]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .local() .format("DD/MM/YY HH:mm"))+" ")])])})}}],null,false,271929232)})],1)],1):_vm._e(),_c('detail',{attrs:{"editing":_vm.editing,"customerAddressId":_vm.customerAddressId,"customerId":_vm.customerId},on:{"update:editing":function($event){_vm.editing=$event},"update:customerAddressId":function($event){_vm.customerAddressId=$event},"update:customer-address-id":function($event){_vm.customerAddressId=$event},"reload":function($event){return _vm.$refs.lstCustAddress.loadData()}}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.showing = false}}},[_vm._v(" Hủy ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.okHandle}},[_vm._v(" Đồng ý ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }