var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cil-NoteAdd"}}),_vm._v(" Tạo đơn hàng ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"info","size":"sm"},on:{"click":function($event){return _vm.$router.push({ name: 'OrderList' })}}},[_c('CIcon',{attrs:{"name":"cil-list"}}),_vm._v(" Danh sách ")],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[(_vm.visitId && _vm.order.CustomerId)?_c('CInput',{attrs:{"value":_vm.order.CustomerName,"label":"Khách hàng","placeholder":"Khách hàng","disabled":""}}):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Khách hàng")]),_c('v-select',{class:{
                    'is-invalid border-danger rounded': !!_vm.inValidObject[
                      'order.CustomerId'
                    ],
                  },attrs:{"options":_vm.customers,"filterable":false,"reduce":function (c) { return c.Id; },"label":"Name","placeholder":"Khách hàng"},on:{"search":_vm.onSearchCustomers,"option:selected":function (e) {
                      _vm.order.BillAddress = {
                        Title: _vm.$const.TITLES[e.Title],
                        FullName: e.LastName
                          ? ((e.LastName) + " " + (e.Name))
                          : e.Name,
                        Mobile: e.Mobile,
                        Address: e.Address,
                        Province: e.Province,
                        District: e.District,
                        Ward: e.Ward,
                      };
                      _vm.order.DeliveryAddress = null;
                      _vm.isSameAddress = false;
                    }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(option.Name))]),_c('p',{staticClass:"mb-1"},[(option.Mobile)?_c('span',[_vm._v(" "+_vm._s(option.Mobile)+" - ")]):_vm._e(),_vm._v(" "+_vm._s([ option.Address, option.Ward, option.District, option.Province ] .filter(function (_) { return _; }) .join(", "))+" ")])])]}}]),model:{value:(_vm.order.CustomerId),callback:function ($$v) {_vm.$set(_vm.order, "CustomerId", $$v)},expression:"order.CustomerId"}},[_c('template',{slot:"no-options"},[_vm._v(" Nhập tên hoặc sđt để tìm kiếm KH ")])],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["order.CustomerId"])+" ")])],1)],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"label":"Địa chỉ hóa đơn","placeholder":"Địa chỉ hóa đơn","value":_vm.billAddress,"disabled":"","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['billAddress'],
                },"invalid-feedback":_vm.inValidObject['billAddress']},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.order.CustomerId)?_c('CButton',{attrs:{"color":"info","size":"sm"},on:{"click":function($event){_vm.selectingAddress = 'BillAddress'}}},[_c('CIcon',{attrs:{"name":"cil-location-pin"}})],1):_vm._e()]},proxy:true}])})],1),_c('CCol',{attrs:{"md":"5"}},[_c('CInput',{staticClass:"position-relative",attrs:{"label":"Địa chỉ giao hàng","placeholder":"Địa chỉ giao hàng","value":_vm.deliveryAddress,"disabled":"","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['deliveryAddress'],
                },"invalid-feedback":_vm.inValidObject['deliveryAddress']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" Địa chỉ giao hàng "),_c('CSwitch',{staticClass:"position-absolute",staticStyle:{"right":"0px","top":"0px"},attrs:{"color":"info","size":"sm","variant":"opposite","shape":"pill","checked":_vm.isSameAddress},on:{"update:checked":function($event){_vm.isSameAddress=$event}}})],1)]},proxy:true},{key:"append",fn:function(){return [(_vm.order.CustomerId && !_vm.isSameAddress)?_c('CButton',{attrs:{"color":"info","size":"sm"},on:{"click":function($event){_vm.selectingAddress = 'DeliveryAddress'}}},[_c('CIcon',{attrs:{"name":"cil-location-pin"}})],1):_vm._e()]},proxy:true}])})],1),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Ngày giao dự kiến ")]),_c('datetime',{class:{
                    'is-invalid': !!_vm.inValidObject[
                      'order.EstimatedDeliveryDate'
                    ],
                  },attrs:{"type":"date","format":"dd/MM/yyyy","input-class":{
                    'form-control': true,
                    'is-invalid': !!_vm.inValidObject[
                      'order.EstimatedDeliveryDate'
                    ],
                  },"value-zone":"local","min-datetime":!_vm.isAdmin
                      ? _vm.$moment()
                          .add(-1, 'day')
                          .startOf('day')
                          .toISOString()
                      : null},model:{value:(_vm.order.EstimatedDeliveryDate),callback:function ($$v) {_vm.$set(_vm.order, "EstimatedDeliveryDate", $$v)},expression:"order.EstimatedDeliveryDate"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["order.EstimatedDeliveryDate"])+" ")])],1)]),_c('CCol',{attrs:{"md":"3"}},[(_vm.visitId && _vm.order.VisitId)?_c('CInput',{attrs:{"value":_vm.order.VisitName,"label":"Thông tin ghé thăm","placeholder":"Thông tin ghé thăm","disabled":""}}):_c('CSelect',{attrs:{"value":_vm.order.VisitId,"label":"Thông tin ghé thăm","placeholder":"Thông tin ghé thăm","options":_vm.visits.map(function (_) {
                    return { value: _.Id, label: _.Name };
                  }),"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['order.VisitId'],
                },"invalid-feedback":_vm.inValidObject['order.VisitId']},on:{"update:value":function($event){return _vm.$set(_vm.order, "VisitId", $event)}}})],1),(_vm.isAdmin)?_c('CCol',{attrs:{"md":"2"}},[_c('CSelect',{attrs:{"label":"Nhân viên kinh doanh","placeholder":"Chọn Nhân viên","value":_vm.order.SellerId,"options":[].concat( _vm.sellers.map(function (_) {
                    return {
                      label: _.Name,
                      value: _.Id,
                    };
                  }) ),"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['order.SellerId'],
                },"invalid-feedback":_vm.inValidObject['order.SellerId']},on:{"update:value":function($event){return _vm.$set(_vm.order, "SellerId", $event)}}})],1):_vm._e(),_c('CCol',{attrs:{"md":_vm.isAdmin ? '4' : '6'}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú"},model:{value:(_vm.order.Note),callback:function ($$v) {_vm.$set(_vm.order, "Note", $$v)},expression:"order.Note"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{ref:"lstOrderDetails",staticClass:"table"},[_c('thead',[_c('tr',{staticClass:"bg-light text-dark"},[_c('th',[_vm._v("STT")]),_c('th',{staticStyle:{"min-width":"200px","max-width":"250px"}},[_vm._v(" Tên SP / CTKM ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[_vm._v(" Đơn giá (VND) ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[_vm._v(" Giảm giá ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[_vm._v(" Tổng tạm (VND) ")]),_c('th',{staticStyle:{"min-width":"50px","max-width":"50px"}})])]),_c('tbody',[(_vm.orderDetails && _vm.orderDetails.length)?_vm._l((_vm.orderDetails),function(orderDetail,i){return _c('tr',{key:("orderDetail-" + i)},[_c('td',[_vm._v(_vm._s(i + 1)+".")]),_c('td',{staticStyle:{"min-width":"200px","max-width":"250px"}},[_vm._v(" "+_vm._s(orderDetail.ProductName)+" "),(!orderDetail.Price)?_c('CIcon',{attrs:{"name":"cil-gift"}}):_vm._e()],1),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[_c('a',{staticClass:"text-dark text-decoration-none",attrs:{"href":"javascript:"},on:{"click":function (e) {
                                _vm.updating = true;
                                _vm.editingDetail = Object.assign(
                                  {},
                                  orderDetail
                                );
                              }}},[_vm._v(" "+_vm._s(orderDetail.Qty)+" x "+_vm._s(_vm.$func.addCommas(orderDetail.MPrice))+" "),_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)]),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[_vm._v(" Giảm "+_vm._s(orderDetail.Price ? ( ((orderDetail.MPrice - orderDetail.Price) / orderDetail.MPrice) * 100 ).toFixed(0) : 100)+"% ")]),_c('td',{staticClass:"text-right font-weight-bold",staticStyle:{"min-width":"150px","max-width":"200px"}},[_c('a',{staticClass:"text-dark text-decoration-none",attrs:{"href":"javascript:"}},[_vm._v(" "+_vm._s(_vm.$func.addCommas( orderDetail.Qty * orderDetail.Price ))+" ")])]),_c('td',{staticStyle:{"min-width":"50px","max-width":"50px"}},[_c('a',{staticClass:"text-decoration-none text-danger",attrs:{"href":"javascript:"},on:{"click":function (e) {
                                _vm.orderDetails = _vm.orderDetails.filter(
                                  function (_) { return _.id != orderDetail.id; }
                                );
                              }}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)])])}):_vm._e(),(_vm.orderPromos && _vm.orderPromos.length)?_vm._l((_vm.orderPromos),function(orderPromo,i){return _c('tr',{key:("orderPromo-" + i)},[_c('td'),_c('td',{staticStyle:{"min-width":"200px","max-width":"250px"}},[_vm._v(" "+_vm._s(orderPromo.PromoName)+" "+_vm._s(0 > orderPromo.Value ? "(Thu phí)" : "")+" "),(orderPromo.Value && orderPromo.Value > 0)?_c('CIcon',{attrs:{"name":"cil-mobile-landscape"}}):_vm._e()],1),_c('td',{staticStyle:{"min-width":"150px","max-width":"200px"}}),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[(orderPromo.Value && orderPromo.Value > 0)?[_vm._v(" Giảm "+_vm._s(_vm.$func.addCommas(orderPromo.Value))+" "+_vm._s(orderPromo.IsPercent ? "%" : "")+" ")]:_vm._e()],2),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"150px","max-width":"200px"}},[_vm._v(" "+_vm._s(orderPromo.Value && orderPromo.Value > 0 ? "-" : "")+" "),(orderPromo.IsPercent)?_c('span',[_vm._v(" "+_vm._s(_vm.$func.addCommas( _vm.subTotal * (orderPromo.Value / 100) ))+" ")]):(orderPromo.Value > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$func.addCommas(orderPromo.Value))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$func.addCommas(-orderPromo.Value))+" ")])]),_c('td',{staticStyle:{"min-width":"50px","max-width":"50px"}},[_c('a',{staticClass:"text-decoration-none text-danger",attrs:{"href":"javascript:"},on:{"click":function (e) {
                                _vm.orderPromos = _vm.orderPromos.filter(
                                  function (_) { return _.id != orderPromo.id; }
                                );
                              }}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)])])}):_vm._e(),_c('tr',[_c('td'),_c('td',{staticClass:"text-right",attrs:{"colspan":"100%"}},[_c('a',{staticClass:"text-info text-decoration-none",attrs:{"href":"javascript:"},on:{"click":function (e) {
                              _vm.updating = true;
                              _vm.editingDetail = { id: 0, Qty: 1, Price: 0 };
                            }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm SP ")],1),_vm._v(" | "),_c('a',{staticClass:"text-info text-decoration-none",attrs:{"href":"javascript:"},on:{"click":function (e) {
                              _vm.discounting = true;
                            }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm CTKM ")],1)])])],2),_c('tfoot',[_c('tr',{staticClass:"bg-light text-dark"},[_c('th',{staticClass:"text-right",attrs:{"colspan":"100%"}},[_vm._v(" Tổng: "),_c('span',{staticClass:"text-dark font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$func.addCommas(_vm.subTotal))+" "+_vm._s(_vm.discount >= 0 ? "-" : "+")+" "),_c('span',{attrs:{"role":"button"}},[_vm._v(" "+_vm._s(_vm.$func.addCommas( _vm.discount >= 0 ? _vm.discount : -_vm.discount ))+" "+_vm._s(_vm.discount >= 0 ? "(giảm giá)" : "(phí)")+" ")])]),_vm._v(" = "+_vm._s(_vm.$func.addCommas(_vm.grandTotal))+" VND ")])])])])])])],1),_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Hình thức thanh toán","placeholder":"Hình thức thanh toán","value":_vm.order.ChargeType,"options":Object.keys(_vm.$const.CHARGE_TYPES_TEXT).map(function (_) {
                    return { value: _, label: _vm.$const.CHARGE_TYPES_TEXT[_] };
                  }),"add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['order.ChargeType'],
                },"invalid-feedback":_vm.inValidObject['order.ChargeType']},on:{"update:value":function($event){return _vm.$set(_vm.order, "ChargeType", $event)}}})],1),(_vm.order.ChargeType == _vm.$const.CHARGE_TYPES.Installment)?_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{staticClass:"text-danger font-weight-bold"},[_vm._v(" Tiền trả trước? ")]),_c('money',{staticClass:"form-control text-right",class:{
                    'is-invalid': !!_vm.inValidObject['order.Prepay'],
                  },attrs:{"disabled":!!(_vm.order.Deposit && _vm.order.Deposit > 0),"placeholder":"Tiền trả trước","value":_vm.order.Prepay},on:{"input":function (e) {
                      _vm.order.Prepay = e
                        ? parseFloat(e) >= 0
                          ? parseFloat(e)
                          : 0
                        : 0;
                    }}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["order.Prepay"])+" ")]),_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(" "+_vm._s(_vm.order.Prepay && _vm.grandTotal && _vm.grandTotal >= _vm.order.Prepay ? ("Trả trước " + (( (_vm.order.Prepay / _vm.grandTotal) * 100 ).toFixed( 0 )) + "% tổng tiền. Chuyển trả góp: " + (_vm.$func.addCommas( _vm.grandTotal - _vm.order.Prepay )) + ". ") : "")+" ")])],1)]):_vm._e(),(_vm.order.ChargeType == _vm.$const.CHARGE_TYPES.Installment)?_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"disabled":!!(_vm.order.Deposit && _vm.order.Deposit > 0),"label":"Thời gian trả góp?","placeholder":"Thời gian trả góp","value":_vm.order.Months,"options":Object.keys(_vm.$const.INSTALLMENT_MONTHS).map(function (_) {
                    return { value: _, label: _vm.$const.INSTALLMENT_MONTHS[_] };
                  }),"addLabelClasses":"text-danger font-weight-bold","add-input-classes":{
                  'is-invalid': !!_vm.inValidObject['order.Months'],
                },"invalid-feedback":_vm.inValidObject['order.Months'],"description":_vm.order.Prepay &&
                  _vm.grandTotal &&
                  _vm.grandTotal >= _vm.order.Prepay &&
                  _vm.order.Months
                    ? ("Trả góp mỗi tháng: " + (_vm.$func.addCommas(
                        Math.round(
                          (_vm.grandTotal - _vm.order.Prepay) / parseInt(_vm.order.Months)
                        )
                      )))
                    : ''},on:{"update:value":function($event){return _vm.$set(_vm.order, "Months", $event)}}})],1):_vm._e(),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Tiền cọc ")]),_c('money',{staticClass:"form-control text-right",class:{
                    'is-invalid': !!_vm.inValidObject['order.Deposit'],
                  },attrs:{"disabled":!!(
                      _vm.order.ChargeType == _vm.$const.CHARGE_TYPES.Installment &&
                      _vm.order.Prepay &&
                      _vm.order.Prepay > 0
                    ),"placeholder":"Tiền cọc","value":_vm.order.Deposit},on:{"input":function (e) {
                      _vm.order.Deposit = e
                        ? parseFloat(e) >= 0
                          ? parseFloat(e)
                          : 0
                        : 0;
                    }}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["order.Deposit"])+" ")]),_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(" "+_vm._s(_vm.order.Deposit && _vm.grandTotal && _vm.grandTotal >= _vm.order.Deposit ? ("Hiện đang đặt cọc " + (( (_vm.order.Deposit / _vm.grandTotal) * 100 ).toFixed(0)) + "% tổng tiền") : "")+" ")])],1)])],1),_c('CRow',[_c('CCol',{attrs:{"md":"1"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{staticClass:"font-weight-bold text-danger"},[_vm._v(" Chia bill? ")]),_c('div',{staticClass:"bg-gradient-light pl-2 pb-2 rounded"},[_c('CSwitch',{staticClass:"col-form-label",attrs:{"color":"info","size":"sm","shape":"pill","label":"","checked":_vm.order.IsShared},on:{"update:checked":function($event){return _vm.$set(_vm.order, "IsShared", $event)}}})],1)])]),(_vm.order.IsShared)?[_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Cách thức chia bill","placeholder":"Chọn cách thức","value":_vm.order.SharedType,"options":Object.keys(_vm.$const.SHARED_TYPES_TEXT).map(function (_) {
                      return { value: _, label: _vm.$const.SHARED_TYPES_TEXT[_] };
                    }),"add-input-classes":{
                    'is-invalid': !!_vm.inValidObject['order.SharedType'],
                  },"invalid-feedback":_vm.inValidObject['order.SharedType']},on:{"update:value":function($event){return _vm.$set(_vm.order, "SharedType", $event)}}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cửa hàng chia bill")]),_c('v-select',{class:{
                      'is-invalid border-danger rounded': !!_vm.inValidObject[
                        'order.SharedAgencyId'
                      ],
                    },attrs:{"options":_vm.agencies,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chọn cửa hàng"},model:{value:(_vm.order.SharedAgencyId),callback:function ($$v) {_vm.$set(_vm.order, "SharedAgencyId", $$v)},expression:"order.SharedAgencyId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["order.SharedAgencyId"])+" ")])],1)]),_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Nhân viên chia bill","placeholder":"Chọn Nhân viên","value":_vm.order.SharedUserId,"options":[].concat( _vm.sharedSellers.map(function (_) {
                      return {
                        label: _.Name,
                        value: _.Id,
                      };
                    }) ),"add-input-classes":{
                    'is-invalid': !!_vm.inValidObject['order.SharedUserId'],
                  },"invalid-feedback":_vm.inValidObject['order.SharedUserId']},on:{"update:value":function($event){return _vm.$set(_vm.order, "SharedUserId", $event)}}})],1),(
                  _vm.order.SharedType &&
                    _vm.order.SharedAgencyId &&
                    _vm.order.SharedUserId
                )?_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-right font-italic"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Diễn giải:")]),_vm._v(" "+_vm._s(_vm.getSharedDescription( _vm.order.SharedType, _vm.order.SharedAgencyId, _vm.order.SharedUserId ))+" ")])]):_vm._e()]:_vm._e()],2)],1),_c('CCardFooter',[_c('CButton',{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.createOrder}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Tạo đơn hàng ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()],1)],1)],1)],1),_c('choose-product',{attrs:{"updating":_vm.updating,"editingDetail":_vm.editingDetail},on:{"update:updating":function($event){_vm.updating=$event},"choose":_vm.updateOrderDetail}}),_c('choose-address',{attrs:{"selectingAddress":_vm.selectingAddress,"customerId":_vm.order.CustomerId},on:{"update:selectingAddress":function($event){_vm.selectingAddress=$event},"update:selecting-address":function($event){_vm.selectingAddress=$event},"choose":_vm.updateAddress}}),_c('choose-promo',{attrs:{"updating":_vm.discounting,"subTotal":_vm.subTotal},on:{"update:updating":function($event){_vm.discounting=$event},"choose":_vm.updateOrderPromo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }