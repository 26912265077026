<template>
  <CModal
    title="Chọn chương trình KM"
    :show.sync="showing"
    :closeOnBackdrop="false"
    size="sm"
    centered
  >
    <CRow>
      <CCol md="12">
        <CSelect
          placeholder="Chọn chương trình KM"
          :value.sync="promotion.PromoId"
          :add-input-classes="{
            'is-invalid': !promotion.PromoId,
          }"
          invalid-feedback="Vui lòng chọn KM"
          :options="
            promotions && promotions.length
              ? promotions.map((_) => {
                  return { value: _.Id, label: _.Name };
                })
              : []
          "
        />
      </CCol>
      <CCol md="12">
        <div class="form-row">
          <CCol col="5">
            <CInput
              type="text"
              label="Loại KM"
              placeholder="Loại KM"
              :value="promotion.IsPercent ? 'Giảm %' : 'Giảm tiền'"
              disabled
            />
          </CCol>
          <CCol col="7">
            <div
              v-if="promotion.PromoId == 99 && !promotion.IsPercent"
              role="group"
              class="form-group"
            >
              <label>
                Số tiền
              </label>
              <money
                placeholder="Số tiền"
                :value="promotion.Value"
                class="form-control text-right"
                :class="{
                  'is-invalid': !promotion.Value || promotion.Value >= 1000000,
                }"
                @input="
                  (e) => {
                    promotion.Value = e ? parseInt(e) : 0;
                    $forceUpdate();
                  }
                "
              ></money>
              <div class="invalid-feedback">
                Số tiền bắt buộc và không vượt quá 1tr
              </div>
            </div>
            <CInput
              v-else
              type="text"
              :label="promotion.IsPercent ? 'Số % giảm' : 'Số tiền giảm'"
              :placeholder="promotion.IsPercent ? 'Số % giảm' : 'Số tiền giảm'"
              :value="
                promotion && promotion.Value
                  ? `${$func.addCommas(promotion.Value)} ${
                      promotion.IsPercent ? '%' : 'VND'
                    }`
                  : ''
              "
              disabled
            />
          </CCol>
        </div>
      </CCol>
      <CCol>
        <CInput
          :plaintext="true"
          label="Thành tiền"
          :horizontal="{ label: 'col-4', input: 'col-8' }"
          :value="`${$func.addCommas(total)} VND`"
        />
      </CCol>
    </CRow>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="showing = false">
        Hủy
      </button>
      <button type="button" class="btn btn-primary" @click="okHandle">
        Đồng ý
      </button>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["updating", "subTotal"],
  data() {
    return {
      promotions: null,
      showing: false,
      promotion: {
        PromoId: 0,
      },
    };
  },
  computed: {
    total() {
      let promotion = this.promotion;
      let subTotal = this.subTotal;
      if (promotion && promotion.PromoId && subTotal) {
        if (promotion.IsPercent) {
          return subTotal * (promotion.Value / 100);
        } else {
          return promotion.Value;
        }
      }
      return 0;
    },
  },
  watch: {
    "promotion.PromoId"(val) {
      if (val) {
        let promotion = this.promotions.find((_) => _.Id == val);
        this.promotion.PromoName = promotion ? promotion.Name : "";
        this.promotion.IsPercent = promotion ? promotion.IsPercent : false;
        this.promotion.Value = promotion ? promotion.Value : 0;
      } else {
        this.promotion.PromoName = "";
        this.promotion.IsPercent = false;
        this.promotion.Value = 0;
      }
    },

    updating(val) {
      this.showing = val;
    },
    showing(val) {
      this.$emit("update:updating", val);
    },
  },
  async mounted() {
    if (this.promotions && this.promotions.length) {
      return;
    }
    this.promotions = await this.loadPromotions();
  },
  methods: {
    async loadPromotions() {
      let promotions = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Promotion", {
        params: {
          $top: 100,
          $filter: `Status eq ${active}`,
          $select: "Id,Name,IsPercent,Value",
          $orderby: "Name asc",
        },
      });
      if (resp && resp.status == 200) {
        promotions = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
            IsPercent: _.IsPercent,
            Value: _.Value,
          };
        });
      }
      return promotions;
    },
    okHandle() {
      let promotion = this.promotion;
      if (
        promotion &&
        promotion.PromoId &&
        promotion.Value &&
        (promotion.PromoId != 99 || promotion.Value < 1000000)
      ) {
        this.$emit("choose", promotion);
        this.promotion = {};
        this.showing = false;
      }
    },
  },
};
</script>
