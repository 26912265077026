<template>
  <CModal
    :title="customerAddress.Id ? 'Cập nhật Địa chỉ' : 'Thêm Địa chỉ'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="6">
            <CSelect
              label="Danh xưng"
              placeholder="Danh xưng"
              :value.sync="customerAddress.Title"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customerAddress.Title'],
              }"
              :invalid-feedback="inValidObject['customerAddress.Title']"
              :options="
                Object.keys($const.TITLES).map((_) => {
                  return {
                    value: _,
                    label: $const.TITLES[_],
                  };
                })
              "
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Tên khách hàng*"
              placeholder="Tên khách hàng"
              v-model="customerAddress.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customerAddress.Name'],
              }"
              :invalid-feedback="inValidObject['customerAddress.Name']"
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Số điện thoại*"
              placeholder="Số điện thoại"
              v-model="customerAddress.Mobile"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customerAddress.Mobile'],
              }"
              :invalid-feedback="inValidObject['customerAddress.Mobile']"
            />
          </CCol>
          <CCol md="6">
            <div class="form-group">
              <label>Tỉnh/Thành*</label>
              <v-select
                v-model="customerAddress.ProvinceCode"
                :options="provinces"
                :reduce="(p) => p.Code"
                label="Name"
                placeholder="Tỉnh/Thành"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'customerAddress.ProvinceCode'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customerAddress.ProvinceCode"] }}
              </div>
            </div>
          </CCol>
          <CCol md="6">
            <div class="form-group">
              <label>Quận/Huyện*</label>
              <v-select
                v-model="customerAddress.DistrictCode"
                :options="districts"
                :reduce="(p) => p.Code"
                label="Name"
                placeholder="Quận/Huyện"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'customerAddress.DistrictCode'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customerAddress.DistrictCode"] }}
              </div>
            </div>
          </CCol>
          <CCol md="6">
            <div class="form-group">
              <label>Phường/Xã</label>
              <v-select
                v-model="customerAddress.WardCode"
                :options="wards"
                :reduce="(p) => p.Code"
                label="Name"
                placeholder="Phường/Xã"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'customerAddress.WardCode'
                  ],
                }"
              />
              <div class="invalid-feedback">
                {{ inValidObject["customerAddress.WardCode"] }}
              </div>
            </div>
          </CCol>
          <CCol md="12">
            <CInput
              label="Địa chỉ*"
              placeholder="Địa chỉ"
              v-model="customerAddress.Address"
              :add-input-classes="{
                'is-invalid': !!inValidObject['customerAddress.Address'],
              }"
              :invalid-feedback="inValidObject['customerAddress.Address']"
            />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton
        class="btn btn-primary"
        @click="saveCustomerAddress"
        :disabled="saving"
      >
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["editing", "customerAddressId", "customerId"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      customerAddress: {},
      saving: false,
      provinces: [],
      districts: [],
      wards: [],
    };
  },
  async mounted() {
    this.provinces = await this.loadProvinces();
  },
  computed: {
    newCustomerAddress() {
      return {
        Id: 0,
        Title: Object.keys(this.$const.TITLES)[0],
        Name: null,
        Mobile: null,
        Address: null,
        AgencyId: null,
        ProvinceCode: null,
        DistrictCode: null,
        WardCode: null,
      };
    },
  },
  watch: {
    async customerAddressId(val) {
      await this.loadCustomerAddressInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:customerAddressId", 0);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },

    async "customerAddress.ProvinceCode"(val) {
      this.districts = await this.loadDistricts(val);
    },
    async "customerAddress.DistrictCode"(val) {
      this.wards = await this.loadWards(val);
    },

    "customerAddress.Mobile"(val) {
      if (val && val.length == 11) {
        alert("Chú ý: Bạn vừa nhập số điện thoại có 11 số!");
      }
    },
  },

  methods: {
    async loadCustomerAddressInfo(customerAddressId) {
      try {
        this.inValidObject = {};
        if (customerAddressId) {
          let filters = [];
          customerAddressId && filters.push(`Id eq ${customerAddressId}`);
          let resp = await this.$http.get(`odata/CustomerAddress`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select:
                "Id,Title,Name,Mobile,Address,ProvinceCode,DistrictCode,WardCode",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.customerAddress = {
              Id: _.Id,
              Title: _.Title,
              Name: _.Name,
              Mobile: _.Mobile,
              Address: _.Address,
              ProvinceCode: _.ProvinceCode,
              DistrictCode: _.DistrictCode,
              WardCode: _.WardCode,
            };
          }
        } else {
          this.customerAddress = Object.assign({}, this.newCustomerAddress);
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveCustomerAddress() {
      let customerAddress = this.customerAddress;
      this.inValidObject = this.checkValid(customerAddress);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let custData = {
          Title: customerAddress.Title,
          Name: customerAddress.Name,
          Mobile: customerAddress.Mobile,
          Address: customerAddress.Address,
          ProvinceCode: customerAddress.ProvinceCode,
          DistrictCode: customerAddress.DistrictCode,
          WardCode: customerAddress.WardCode,
        };

        if (!customerAddress.Id) {
          // post
          custData.CustomerId = this.customerId;
          custData.IsPrimary = false;

          let resp = await this.$http.post(`odata/CustomerAddress`, custData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/CustomerAddress/${customerAddress.Id}`,
            custData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(customerAddress) {
      let inValidObject = {};

      if (!customerAddress.Title)
        inValidObject["customerAddress.Title"] = "Vui lòng chọn Danh xưng!";
      if (!customerAddress.Name)
        inValidObject["customerAddress.Name"] = "Vui lòng nhập Tên khách hàng!";
      if (!customerAddress.Mobile) {
        inValidObject["customerAddress.Mobile"] =
          "Vui lòng nhập Số điện thoại!";
      } else {
        //let mReg = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
        let mReg = /([0]{1}[0-9]{9,10})\b/g;
        let mRes = mReg.test(customerAddress.Mobile);
        if (!mRes) {
          inValidObject["customerAddress.Mobile"] = "Số điện thoại chưa đúng!";
        }
      }
      if (!customerAddress.Address)
        inValidObject["customerAddress.Address"] = "Vui lòng nhập Địa chỉ!";
      if (!customerAddress.ProvinceCode)
        inValidObject["customerAddress.ProvinceCode"] =
          "Vui lòng nhập Tỉnh/thành!";
      if (!customerAddress.DistrictCode)
        inValidObject["customerAddress.DistrictCode"] =
          "Vui lòng nhập Quận/huyện!";

      return inValidObject;
    },

    async loadProvinces() {
      let result = [];
      let resp = await this.$http.get("odata/Province", {
        params: { $top: 100, $select: "Code,Name" },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Code: _.Code,
            Name: _.Name,
          };
        });
      }
      return result;
    },

    async loadDistricts(provinceCode) {
      let result = [];
      if (provinceCode) {
        let resp = await this.$http.get("odata/District", {
          params: {
            $top: 100,
            $select: "Code,Name",
            $filter: `ProvinceCode eq '${provinceCode}'`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Code: _.Code,
              Name: _.Name,
            };
          });
        }
      }
      return result;
    },

    async loadWards(districtCode) {
      let result = [];
      if (districtCode) {
        let resp = await this.$http.get("odata/Ward", {
          params: {
            $top: 100,
            $select: "Code,Name",
            $filter: `DistrictCode eq '${districtCode}'`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value.map((_) => {
            return {
              Code: _.Code,
              Name: _.Name,
            };
          });
        }
      }
      return result;
    },
  },
};
</script>
