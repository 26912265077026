var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.customerAddress.Id ? 'Cập nhật Địa chỉ' : 'Thêm Địa chỉ',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":""},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CSelect',{attrs:{"label":"Danh xưng","placeholder":"Danh xưng","value":_vm.customerAddress.Title,"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customerAddress.Title'],
            },"invalid-feedback":_vm.inValidObject['customerAddress.Title'],"options":Object.keys(_vm.$const.TITLES).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.TITLES[_],
                };
              })},on:{"update:value":function($event){return _vm.$set(_vm.customerAddress, "Title", $event)}}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Tên khách hàng*","placeholder":"Tên khách hàng","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customerAddress.Name'],
            },"invalid-feedback":_vm.inValidObject['customerAddress.Name']},model:{value:(_vm.customerAddress.Name),callback:function ($$v) {_vm.$set(_vm.customerAddress, "Name", $$v)},expression:"customerAddress.Name"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":"Số điện thoại*","placeholder":"Số điện thoại","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customerAddress.Mobile'],
            },"invalid-feedback":_vm.inValidObject['customerAddress.Mobile']},model:{value:(_vm.customerAddress.Mobile),callback:function ($$v) {_vm.$set(_vm.customerAddress, "Mobile", $$v)},expression:"customerAddress.Mobile"}})],1),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tỉnh/Thành*")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'customerAddress.ProvinceCode'
                ],
              },attrs:{"options":_vm.provinces,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Tỉnh/Thành"},model:{value:(_vm.customerAddress.ProvinceCode),callback:function ($$v) {_vm.$set(_vm.customerAddress, "ProvinceCode", $$v)},expression:"customerAddress.ProvinceCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customerAddress.ProvinceCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Quận/Huyện*")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'customerAddress.DistrictCode'
                ],
              },attrs:{"options":_vm.districts,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Quận/Huyện"},model:{value:(_vm.customerAddress.DistrictCode),callback:function ($$v) {_vm.$set(_vm.customerAddress, "DistrictCode", $$v)},expression:"customerAddress.DistrictCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customerAddress.DistrictCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Phường/Xã")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'customerAddress.WardCode'
                ],
              },attrs:{"options":_vm.wards,"reduce":function (p) { return p.Code; },"label":"Name","placeholder":"Phường/Xã"},model:{value:(_vm.customerAddress.WardCode),callback:function ($$v) {_vm.$set(_vm.customerAddress, "WardCode", $$v)},expression:"customerAddress.WardCode"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["customerAddress.WardCode"])+" ")])],1)]),_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Địa chỉ*","placeholder":"Địa chỉ","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['customerAddress.Address'],
            },"invalid-feedback":_vm.inValidObject['customerAddress.Address']},model:{value:(_vm.customerAddress.Address),callback:function ($$v) {_vm.$set(_vm.customerAddress, "Address", $$v)},expression:"customerAddress.Address"}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveCustomerAddress}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }